import { useRef } from 'react'
import { Box, SimpleGrid, Heading, Text, Image } from '@chakra-ui/react'
import Lottie from 'react-lottie-player'

import Container from 'layouts/Container'

import playAnimation from 'animations/features/play.json'
import puzzleAnimation from 'animations/features/practice.json'
import learnAnimation from 'animations/features/learning.json'

const features = [
  {
    id: 1,
    animation: puzzleAnimation,
    title: 'Puzzles',
    description: `Our Puzzles have detailed solutions which are speech-enabled.`,
  },
  {
    id: 2,
    animation: learnAnimation,
    title: 'Learn',
    description: `All lessons and exercises on this site have been created by teacher Knarik.`,
  },
  {
    id: 3,
    animation: playAnimation,
    title: 'Play',
    description: `PLay against other children in a mentored safe environment, or take on Sásta, our chess-playing Penguin with an attitude.`,
  },
]

export default function Features() {
  return (
    <Container position="relative" mt={-6}>
      <Image
        src="/images/blob1.svg"
        position="absolute"
        w="800px"
        h="800px"
        top={-48}
        left={-24}
        zIndex={-1}
      />
      <Image
        src="/images/blobOutline2.svg"
        position="absolute"
        w="800px"
        h="800px"
        top={32}
        right={0}
        zIndex={-1}
      />
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={12}
        rounded="3xl"
        borderWidth={1}
        boxShadow="2xl"
        bg="white"
        py={4}
        zIndex={99}
      >
        {features.map((feature) => (
          <Feature key={feature.id} feature={feature} />
        ))}
      </SimpleGrid>
      <SimpleGrid
        display={{ base: 'none', md: 'grid' }}
        columns={{ base: 1, md: 3 }}
        spacing={{ base: 8, lg: 16 }}
        shadow="4xl"
        rounded="3xl"
        bg="orange.400"
        borderWidth={4}
        borderColor="orange.200"
        px={{ base: 2 }}
        py={8}
        mt={8}
      >
        {features.map((feature) => (
          <FeatureDescription key={feature.id}>
            {feature.description}
          </FeatureDescription>
        ))}
      </SimpleGrid>
    </Container>
  )
}

function Feature({ feature }) {
  const lottieRef = useRef()

  return (
    <Box justify="center" mx="auto" zIndex={1}>
      <Box
        _hover={{
          backgroundSize: 'cover',
          backgroundImage: {
            md: `url('/images/bg2.png')`,
          },
        }}
      >
        <Lottie
          ref={lottieRef}
          onMouseEnter={() => lottieRef.current?.play()}
          onMouseLeave={() => {
            lottieRef.current?.goToAndStop(0)
          }}
          animationData={feature.animation}
          style={{ height: 180 }}
        />
      </Box>
      <Text
        as="h2"
        textAlign="center"
        color="orange.500"
        fontSize="2xl"
        fontWeight="extrabold"
      >
        {feature.title}
      </Text>
      <FeatureDescription
        display={{ md: 'none' }}
        bg="orange.500"
        mt={4}
        rounded="none"
      >
        {feature.description}
      </FeatureDescription>
    </Box>
  )
}

function FeatureDescription({ children, ...styles }) {
  return (
    <Box
      justify="center"
      mx="auto"
      p={{ base: 2, lg: 4 }}
      rounded="md"
      _hover={{
        bg: 'orange.500',
      }}
      {...styles}
    >
      <Heading
        as="h4"
        textAlign="center"
        color="white"
        fontSize={{ base: 'xl', md: 'lg', lg: 'xl' }}
      >
        {children}
      </Heading>
    </Box>
  )
}
