import { Box, SimpleGrid, Flex, Heading, Image } from '@chakra-ui/react'
import Lottie from 'react-lottie-player'

import Container from 'layouts/Container'

export default function Puzzles() {
  return (
    <Container py={{ base: 24, md: 48 }} position="relative">
      <Image
        src="/images/blob2.svg"
        position="absolute"
        w="900px"
        top={48}
        right={0}
        zIndex={-1}
      />
      <Heading textAlign="center" textColor="#2D81A5">
        Explore new puzzles every week!
      </Heading>
      <Flex
        align="center"
        justify="space-around"
        direction={{ base: 'column', lg: 'row' }}
        mt={12}
      >
        <Flex
          w={{ base: '100%', lg: '48%' }}
          position="relative"
          h={{ base: '400px', lg: '500px' }}
          justify="center"
          align="center"
        >
          <Image
            position="absolute"
            src="/images/PuzzleQuestion3.png"
            width="100%"
            top={0}
            zIndex={-1}
          />
        </Flex>
        <Box>
          <Image src="/images/puzzleBoard.png" zIndex={-1} maxH="500px" />
        </Box>
      </Flex>
    </Container>
  )
}
