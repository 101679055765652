import { Box, SimpleGrid, Heading, Flex, Image } from '@chakra-ui/react'
import Lottie from 'react-lottie-player'

import Container from 'layouts/Container'
import anim1 from 'animations/animatePieceOne.json'
import anim2 from 'animations/animatePieceTwo.json'
import anim3 from 'animations/animatePieceThree.json'

export default function AnimatedPieces() {
  return (
    <Container py={{ base: 12, md: 24 }} position="relative">
      <Heading mb={12} textAlign="center" textColor="#2D81A5">
        Play with animated pieces
      </Heading>
      <Image
        src="/images/blobOutline3.svg"
        position="absolute"
        w="75%"
        top={0}
        left={24}
        zIndex={-1}
      />
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={12}
        rounded="3xl"
        borderWidth={1}
        boxShadow="2xl"
        bg="white"
        py={12}
      >
        <Box justify="center" mx="auto">
          <Lottie animationData={anim1} style={{ height: 200 }} loop play />
        </Box>
        <Box justify="center" mx="auto">
          <Lottie animationData={anim2} style={{ height: 200 }} loop play />
        </Box>
        <Box justify="center" mx="auto">
          <Lottie animationData={anim3} style={{ height: 200 }} loop play />
        </Box>
      </SimpleGrid>
    </Container>
  )
}
