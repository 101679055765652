import { Box, Flex, Heading, Text, Image } from '@chakra-ui/react'

function Videos() {
  return (
    <Box py={16} maxW="1440px" mx="auto" position="relative">
      <Image
        src="/images/blob1.svg"
        position="absolute"
        w="400px"
        h="400px"
        top={48}
        left={-24}
        zIndex={-1}
      />
      <Image
        src="/images/blobOutline2.svg"
        position="absolute"
        w="500px"
        top={24}
        right={0}
        zIndex={-1}
      />
      <Heading textAlign="center" fontWeight="extrabold" textColor="#2D81A5">
        Introduction by Knarik
      </Heading>

      <Flex justify="center" mt={8}>
        <Box
          rounded="lg"
          overflow="hidden"
          mt={4}
          borderWidth={{ base: 2, md: 6 }}
          borderColor="orange.300"
          maxW="3xl"
        >
          <video controls>
            <source src="/videos/intro.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      </Flex>
    </Box>
  )
}

export default Videos
