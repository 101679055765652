import Auth from 'components/Guards/Auth'
import Routes from './Routes'

import ZoomMountEl from 'components/ZoomSdkElement/ZoomMountElements'

function App() {
  return (
    <Auth>
      <Routes />
      <ZoomMountEl />
    </Auth>
  )
}

export default App
