import {
  Box,
  SimpleGrid,
  Heading,
  Text,
  Image,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react'
import { MdCheckCircle } from 'react-icons/md'

import Container from 'layouts/Container'

export default function Lessons() {
  return (
    <Container py={{ base: 12, md: 24 }} position="relative" overflow="hidden">
      <Image
        src="/images/blob1.svg"
        position="absolute"
        w={{ lg: '800px' }}
        h={{ lg: '800px' }}
        top={24}
        left={{ lg: 48 }}
        zIndex={-1}
      />
      <Heading mb={{ base: 12, md: 16 }} textAlign="center" textColor="#2D81A5">
        Interactive Lessons
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={12} align="center">
        <Box justify="center" mx="auto" order={{ base: 2, md: 1 }}>
          <Image src="/images/lessonBoard.png" />
        </Box>
        <Box
          alignSelf="center"
          order={{ base: 1, md: 2 }}
          mb={{ base: 8, md: 0 }}
        >
          <Box
            maxW="520px"
            borderWidth={2}
            rounded="3xl"
            p={{ base: 8, md: 6, xl: 12 }}
            bg="white"
            shadow="2xl"
          >
            <Heading
              textAlign="center"
              color="#F56F0E"
              fontSize={{ base: '3xl', md: '4xl' }}
            >
              The scholar&#39;s mate!
            </Heading>
            <List spacing={8}>
              <ListItem display="flex" align="center" mt={4}>
                <ListIcon
                  as={MdCheckCircle}
                  color="#204E78"
                  mt={1}
                  fontSize="2xl"
                  display={{ base: 'none', md: 'flex' }}
                />
                <Text fontSize={{ base: 'lg', md: 'xl' }} textAlign="left">
                  Opening with your king's pawn allows you to move your
                  white-square bishop and also your queen.
                </Text>
              </ListItem>
              <ListItem display="flex" align="center" mt={4}>
                <ListIcon
                  as={MdCheckCircle}
                  color="#204E78"
                  mt={1}
                  fontSize="2xl"
                  display={{ base: 'none', md: 'flex' }}
                />
                <Text fontSize={{ base: 'lg', md: 'xl' }} textAlign="left">
                  Remember, both the bishop and the queen are needed for the
                  scholar's mate.
                </Text>
              </ListItem>
            </List>
          </Box>
        </Box>
      </SimpleGrid>
    </Container>
  )
}
