import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  Image,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'

import Container from 'layouts/Container'
import Scene from './Scene'

const MotionButton = motion(Button)

export default function Hero() {
  return (
    <Container position="relative" mb={{ base: 36, md: 0 }}>
      <Box
        position="absolute"
        top={{ base: 280, md: 24 }}
        right={0}
        zIndex={-1}
      >
        <Image src="/images/blobOutline1.svg" w="800px" />
      </Box>
      <Box position="absolute" top={36} left={{ base: 0, md: 400 }} zIndex={-1}>
        <Image src="/images/blob.svg" w="200px" />
      </Box>
      <Stack direction={{ base: 'column', md: 'row' }} pb={16}>
        <Flex
          p={{ base: 4, md: 8 }}
          pl={0}
          flex={1}
          align={'center'}
          justify={'center'}
          mt={16}
        >
          <Stack
            spacing={4}
            justify={{ base: 'center', md: 'start' }}
            w={{ md: 'full' }}
          >
            <Box>
              <Heading
                as="h1"
                fontSize={{ base: '4xl', md: '5xl', lg: '6xl' }}
                textColor="#2D81A5"
                m={0}
                p={0}
              >
                Animated Chess
              </Heading>
              <Text fontSize={{ base: 'lg', lg: 'xl' }} color="orange.500">
                with Chess Master Knarik Mouradian.
              </Text>
            </Box>

            <MotionButton
              colorScheme="orange"
              w={56}
              py={8}
              as="a"
              href="/signup"
              size="lg"
              fontSize="3xl"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Join Now
            </MotionButton>
          </Stack>
        </Flex>
        <Flex
          flex={1}
          justify="center"
          align="center"
          position="relative"
          overflow="hidden"
        >
          <Scene />
        </Flex>
      </Stack>
    </Container>
  )
}
